import Vuex, { MutationTree, ActionTree } from "vuex";

export interface WorklogState {
  hideEarlyMorning: boolean;
}

const state: WorklogState  = {
  hideEarlyMorning: true
}

const mutations: MutationTree<WorklogState> = {
  // functions here...
  toggleEarlyMorning(state) {
    state.hideEarlyMorning = !state.hideEarlyMorning;
  }
}

const actions: ActionTree<WorklogState, WorklogState> = {
  toggleEarlyMorning({commit, state}) {
    commit('toggleEarlyMorning');
  }
}

export const store = new Vuex.Store<WorklogState>({
    state: state,
    mutations: mutations,
    actions: actions
});
