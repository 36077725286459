






import Vue from "vue";
import Component from "vue-class-component";
import "lodash.combinations";
// @ts-ignore
import Awesomplete from "awesomplete";
import { Prop } from "vue-property-decorator";
import { api } from "../javascripts/api";
import $ from "jquery";
import _ from "lodash";
import { Guid } from "guid-typescript";

@Component({
  name: "autocomplete",
  components: {},
  props: {
    inputClass: String,
    value: String
  }
})
export default class Autocomplete extends Vue {
  private static generateUUID() {
    return Guid.create();
  }

  get uid(): string {
    return this["_uid"];
  }

  async mounted() {
    // OK, it's going to be on the data-uid attribute...
    const input: HTMLElement = $(`input[data-uid="${this.uid}"]`)
      .first()
      .get(0);
    if (input == null) {
      throw "cannot find input element for UID: " + this.uid;
    }

    const awesomplete = new Awesomplete(input, {
      autoFirst: true,
      minChars: 1
    });

    awesomplete.list = [];
    const component = this;

    $(input).on("blur", function() {
      const newVal = $(input).val();
      if (newVal !== null && newVal !== undefined) {
        component.$emit("input", newVal.toString());
      }
    });

    let throttledUpdate = _.debounce(async () => {
      // we only care if autocomplete hasn't completed yet
      const inputString = $(input).val();

      if (inputString == null || inputString == undefined) {
        return;
      }

      if (awesomplete[0] == inputString) {
        awesomplete.close();
      }
      const newSuggestions = await api().customerAutoComplete(
        inputString.toString()
      );
      if (!_.includes(newSuggestions, inputString.toString())) {
        awesomplete.list = newSuggestions;
        awesomplete.evaluate();
      }
    }, 250);

    input.addEventListener("awesomplete-selectcomplete", function(event) {
      awesomplete.list = [];
    });

    $(input).keydown(event => {
      if (event.key == "Enter") {
        awesomplete.list = [];
      }
    });

    $(input).keyup(event => {
      if (event.key != undefined && event.key.match(/^[A-Za-z]{1}$/)) {
        throttledUpdate();
      }
    });
  }
}
