export default class TimeOfDay {
    hour: number;
    minute: number;
    
    constructor(hour: number, minute: number) {
        if (hour < 0 || hour > 23) {
            throw new RangeError(`Invalid hour: ${hour}`)
        }

        if (minute < 0 || minute > 59) {
            throw new RangeError(`Invalid minute: ${minute}`)
        }

        this.hour = hour;
        this.minute = minute;
    }

    private pad(num: number, size: number): string {
        let s = num+"";
        while (s.length < size) s = "0" + s;
        return s;
    }

    toString(): string {
        return `${this.hour}:${this.pad(this.minute, 2)}`
    }

    to12HourString(): string {
        let ampm = this.hour >= 12 ? "PM" : "AM";
        let newHour = this.hour > 12 ? this.hour - 12 : this.hour;
        return `${newHour}:${this.pad(this.minute, 2)} ${ampm}`;
    }

    static from12HourString(val: string): TimeOfDay | null {
        let matches: RegExpMatchArray | null = val.toUpperCase().match(/([0-9]{1,2}):([0-9]{2})\s*(AM|PM)/)
        if (matches == null) {
            return null;
        }
        let hour = Number(matches[1]);
        let minute = Number(matches[2]);
        let ampm = matches[3];
        if (ampm == "PM" && hour < 12) {
            hour += 12;
        }
        return new TimeOfDay(hour, minute);
    }

    plus(minutes: number): TimeOfDay {
        let newMinutes = this.minute + minutes;
        // wrap dang minutes...
        if (newMinutes >= 60) {
            let remainder = newMinutes - 60;
            let newHour = this.hour + 1;
            // wrap dang hour...
            if (newHour > 23) {
                newHour = 0;
            }
            return new TimeOfDay(newHour, remainder);
        }
        return new TimeOfDay(this.hour, newMinutes);
    }

    valueOf(): number {
        return this.hour * 60 + this.minute;
    }

    delta(other: TimeOfDay): number {
        const hourDiff = Math.abs(other.hour - this.hour);
        const minuteDifference = other.minute - this.minute;
        const fractionalHour = minuteDifference / 60;
        return hourDiff + fractionalHour;
    }
}