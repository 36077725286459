export interface ServiceData {
    service_id: number,
    name: string
}

export default class Service {
    private serviceId: number | null;
    private name: string;

    constructor(serviceId : number | null, name: string) {
        this.serviceId = serviceId;
        this.name = name;
    }
}