import TimeOfDay from "./time-of-day";
import moment from "moment";
import _ from "lodash";
import ElementBoundsFinder from "./element_bounds_finder";

export class TimePixelUtilities {
    private readonly hourElementHeight: number;

    constructor(hourElementHeight: number) {
        this.hourElementHeight = hourElementHeight;
    }

    timeToPixels(time: TimeOfDay, hideEarlyMorning: boolean): number {
        let hours = time.hour * 60;
        if (hideEarlyMorning) {
            hours = hours - 8 * 60;
        }
        return this.deltaToPixelHeight(hours + time.minute);
    }

    calculateSelectionHeight(startedAt: TimeOfDay, endedAt: TimeOfDay): number {
        return this.deltaToPixelHeight(this.calculateDelta(startedAt, endedAt));
    }

    private calculateDelta(startedAt: TimeOfDay, endedAt: TimeOfDay): number {
        let startMoment = moment(`${startedAt.hour}:${startedAt.minute}`, 'hh:mm');
        let endMoment = moment(`${endedAt.hour}:${endedAt.minute}`, 'hh:mm');
        return endMoment.diff(startMoment, "minutes");
    }

    deltaToPixelHeight(deltaMinutes: number) {
        // that gets us the hour
        return (deltaMinutes / 60) * this.hourElementHeight
    }

    timeAtCoordinate(event: MouseEvent): TimeOfDay | null {
        const hourElement = ElementBoundsFinder.firstInBoundsWithClass(event, "hour");
        if (hourElement == null) {
            return null;
        }
        const hour = Number(hourElement.getAttribute("data-hour"));
        const minute = this.calculateMinute(hourElement as HTMLElement, event);
        return new TimeOfDay(hour, minute);
    }

    calculateMinute(hourElement: HTMLElement, event: MouseEvent): number {
        const bounds = hourElement.getBoundingClientRect();
        const y = event.clientY - bounds.top;
        const height = bounds.height;
        const percentage = (y / height) * 100;
        if (percentage < 25) {
            return 0;
        } else if (percentage < 50) {
            return 15;
        } else if (percentage < 75) {
            return 30;
        } else if (percentage <= 100) {
            return 45;
        } else {
            throw new Error("We are more than 100% into an element! This should not occur...");
        }
    };
}