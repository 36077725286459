












import Vue from "vue";
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import Entry from "../javascripts/entry";

@Component({
    name: 'entry-cell'
})
export default class EntryCell extends Vue {
    @Prop(Entry) entry!: Entry;
}
