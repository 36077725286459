import {cookie} from "./cookie";
import API from "./api";
import _ from "lodash";

export default class PasswordStrengthBinder {
    private readonly element: HTMLInputElement;
    private callback: (result: string) => void;
    constructor(element: HTMLInputElement, callback: (result: string) => void) {
        this.element = element;
        this.callback = callback;
        this.bind();
    }

    bind() {
        // ok, so we have a password element, let's bind to it
        // and then we will post to the API and get back the strength
        // and display to user...
        let passwordElement = this.element;
        // bind the keyup event to the element
        passwordElement.addEventListener('keyup', _.debounce(async () => {
            let password = passwordElement.value;
            if (password != "") {
                // post to API here...
                let username = cookie.read('username');
                if (username == null || password == undefined) {
                    throw ("Username/password cannot be determined.")
                } else {
                    let result = await API.checkPassword(username, password.toString());
                    let resultText = result['strength'];
                    this.callback(resultText);
                }
            }
        }));
    }
}