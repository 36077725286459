import TimeOfDay from "./time-of-day";
import * as _ from "lodash";
import moment from "moment";

function titleize(str: string): string {
    return _.startCase(_.camelCase(str))
}

export interface EntryData {
    entry_id: number,
    start_time: Date,
    end_time: Date,
    customer_name: string | null,
    customer_id?: number,
    service_id?: number,
    service_name: string | null,
    description: string,
    employee_id?: number
}

/**
 * Represents a unit of work
 */
export default class Entry {

    startTime: Date;
    endTime: Date;
    _customerName: string | null;
    serviceName: string | null;
    description: string = "";

    constructor(readonly id: number | null, customerName: string | null, serviceName: string | null, startTime: Date, endTime: Date, description: string) {
        this._customerName = titleize(customerName || "");
        this.serviceName = serviceName;
        this.startTime = startTime;
        this.endTime = endTime;
        this.description = description
    }

    isOnDate(date: Date): boolean {
        return this.startTime.getDate() == date.getDate() || this.endTime.getDate() == date.getDate();
    }

    isDuring(timeOfDay: TimeOfDay): boolean {
        return timeOfDay >= this.startTimeOfDay && timeOfDay < this.endTimeOfDay;
    }

    get onDate(): Date {
        return this.startTime;
    }

    set onDate(date: Date) {
        // TODO: probably only want to extract day, week, month
        const newDate = date.getDate();
        const newMonth = date.getMonth();
        const newYear = date.getFullYear();
        this.startTime.setMonth(newMonth, newDate);
        this.startTime.setFullYear(newYear);
        this.endTime.setMonth(newMonth, newDate);
        this.endTime.setFullYear(newYear);
        this.startTime = new Date(this.startTime.getTime());
        this.endTime = new Date(this.endTime.getTime());
    }

    get startTimeOfDay(): TimeOfDay {
        return new TimeOfDay(this.startTime.getHours(), this.startTime.getMinutes());
    }

    get endTimeOfDay(): TimeOfDay {
        return new TimeOfDay(this.endTime.getHours(), this.endTime.getMinutes());
    }

    get dayOfWeek(): string {
        if (this.startTime) {
            switch (this.startTime.getDay()) {
                case 0: return "Sun";
                case 1: return "Mon";
                case 2: return "Tue";
                case 3: return "Wed";
                case 4: return "Thur";
                case 5: return "Fri";
                case 6: return "Sat";
            }
            throw "Can't figure out what the hell day for " + this.startTime.getDay();
        } else {
            return "UNKNOWN";
        }
    }

    set customerName(name: string | null) {
        if (name != null) {
            this._customerName = titleize(name);
        } else {
            this._customerName = "";
        }
    }

    get customerName(): string | null {
        return this._customerName;
    }

    set startTimeOfDay(val: TimeOfDay) {
        this.startTime.setHours(val.hour, val.minute);
        if (this.startTime.toString() == "Invalid Date") {
            throw "Invalid date!";
        }
    }

    set endTimeOfDay(val: TimeOfDay) {
        this.endTime.setHours(val.hour, val.minute);
        if (this.endTime.toString() == "Invalid Date") {
            throw "Invalid date!";
        }
    }

    get startTimeString(): string {
        return this.startTimeOfDay.to12HourString();
    }

    set startTimeString(val: string) {
        let newTimeOfDay = TimeOfDay.from12HourString(val);
        if (newTimeOfDay != null) {
            let newTime = new Date(this.startTime.getTime());
            newTime.setHours(newTimeOfDay.hour, newTimeOfDay.minute);
            this.startTime = newTime;
        }
    }

    get endTimeString(): string {
        return this.endTimeOfDay.to12HourString();
    }

    set endTimeString(val: string) {
        let newTimeOfDay = TimeOfDay.from12HourString(val);
        if (newTimeOfDay != null) {
            let newTime = new Date(this.endTime.getTime());
            newTime.setHours(newTimeOfDay.hour, newTimeOfDay.minute);
            this.endTime = newTime;
        }
    }

    get timeDescription(): string {
        return `${this.startTimeOfDay.to12HourString()} ➞ ${this.endTimeOfDay.to12HourString()}`
    }

    durationInMinutes(): number {
        var end = moment(this.startTime); //todays date
        var now = moment(this.endTime); // another date
        var duration = moment.duration(now.diff(end));
        return duration.asMinutes();
    }

    get durationInHours(): number {
        return this.durationInMinutes() / 60;
    }

    get durationString(): string {
        const duration = this.durationInMinutes() / 60;
        if (duration == 1) {
            return `${duration.toFixed(2)} hour`;
        } else {
            return `${duration.toFixed(2)} hours`;
        }
    }

    get tooltip(): string {
        return `${this.customerName} - (${this.serviceName})\n` +
               `${this.timeDescription} (${this.durationString})\n` +
               `---------\n` +
               `${this.description}`
    }

    toData(): EntryData {
        return {
            entry_id: Number(this.id),
            start_time: this.startTime,
            end_time: this.endTime,
            customer_name: this.customerName,
            service_name: this.serviceName,
            description: this.description
        }
    }
}