import * as $ from "jquery";
(<any>window).jQuery = $;

import "bootstrap";
import Home from "./home";
import PasswordChecker from "./password_checker";
import PasswordStrengthBinder from "./password_strength_binder";
import Vue from "vue";
import Vuex from 'vuex'

Vue.use(Vuex);
import Swagger from "./swagger-ui";
// let's get that material going
import material from 'daemonite-material/js/material';
export const EventBus = new Vue();

declare var window : any;
window.$ = window.jQuery = $;

// @ts-ignore
module.exports = {
    Swagger: Swagger,
    Home: Home,
    PasswordChecker: PasswordChecker,
    PasswordStrengthBinder: PasswordStrengthBinder,
    // @ts-ignore
    $: $.default,
    material: material
};