







































import Vue from "vue";
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import TimeOfDay from "../javascripts/time-of-day";
import {EventBus} from "../javascripts/app";
import WorkWeek from "./workweek.vue"
import EntryCell from "./entry-cell.vue";
import Entry from "../javascripts/entry";
import {TimePixelUtilities} from "../javascripts/time_pixel_utilities";
import _ from 'lodash';
import 'lodash.combinations';
import ElementBoundsFinder from "../javascripts/element_bounds_finder";

@Component({
    name: 'day',
    components: {
        EntryCell
    }
})
export default class Day extends Vue {
    @Prop(Date) date!: Date;

    startedAt: TimeOfDay | null = null;
    endedAt: TimeOfDay | null = null;
    isSelecting: Boolean = false;
    private hourElementHeight = 150; // set this in the style way down bottom too...
    private timePixelUtilities = new TimePixelUtilities(this.hourElementHeight);

    private entryColumns = new Map<Entry, number | null>();

    static weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'];
    private notLeftClick: boolean = false;

    get hideEarlyMorning() {
        return this.$store.state.hideEarlyMorning;
    }

    get day(): String {
        return Day.weekdays[this.date.getDay()]
    }

    get totalHours(): number {
        return this.entries.reduce((total, entry) => {
            return total + entry.durationInHours;
        }, 0);
    }

    get maybeOffhours(): String {
        if (this.day == "Sat" || this.day == 'Sun') {
            return 'offhours';
        }
        return '';
    }

    get entries(): Array<Entry> {
        // get entries from parent...
        const workWeek = this.$parent as WorkWeek;
        const entries = workWeek.entriesFor(this.date);

        // reinitialize column mapping...
        this.entryColumns = new Map<Entry, number>();

        // let's set each one to zero...
        _.each(entries, (entry: Entry) => {
            this.entryColumns.set(entry, 0);
        });

        // make sure none will overlap...
        return this.handleOverlaps(entries);
    }

    get selectionStyle() {
        let styles = {
            'height': '50px',
            'width': '100%',
            'background-color': 'blue',
            'color': 'white',
            'margin-top': '0'
        };

        if (this.isSelecting && this.endedAt && this.startedAt) {
            styles['margin-top'] = `${this.timePixelUtilities.timeToPixels(this.startedAt, this.hideEarlyMorning)}px`;
            const selectionHeight = this.timePixelUtilities.calculateSelectionHeight(this.startedAt, this.endedAt);
            styles['height'] = `${selectionHeight}px`;
        }
        return styles;
    }

    get selectionText(): String {
        if (this.startedAt == null || this.endedAt == null) {
            return "UNKNOWN!"
        } else {
            return `${this.startedAt.toString()} - ${this.endedAt.toString()}`
        }
    }

    mouseLeave() {
        this.isSelecting = false;
    }

    mouseMoved(evt: MouseEvent) {
        if (this.isSelecting) {
            let over = this.timePixelUtilities.timeAtCoordinate(evt);
            if (over) {
                this.endedAt = over.plus(15);
            }
        }
    }

    styleForCell(entry: Entry) {
        // figure out how far up to position the elements...
        let cellStyle = {
            top: this.timePixelUtilities.timeToPixels(entry.startTimeOfDay, this.hideEarlyMorning) + "px",
            height: this.timePixelUtilities.deltaToPixelHeight(entry.durationInMinutes()) + "px",
            width: '100%',
            left: 'auto'
        };

        let overlappedCellWidth = (1 / (this.maxColumn() + 1)) * 100;

        // now see if we have to do any stupid column jazz...
        let columnForCell = this.entryColumns.get(entry);
        if (columnForCell != undefined && columnForCell != null) {
            cellStyle['width'] = `${overlappedCellWidth}%`;
            cellStyle['left'] = `${overlappedCellWidth * columnForCell}%`;
        }

        return cellStyle;
    }

    private maxColumn(): number {
        let allColumns = Array.from(this.entryColumns.values());
        return _.max(allColumns) || 0;
    }

    mouseDown(evt: MouseEvent) {
        // if it's not a left click who cares...
        if (evt.button != 0) {
            this.notLeftClick = true;
            return;
        } else {
            this.notLeftClick = false;
        }

        const entryElement = ElementBoundsFinder.firstInBoundsWithClass(evt, "entry-cell");
        if (entryElement) {
            const entryId = Number(entryElement.getAttribute('data-entry-id'));
            if (entryId) {
                let entryToEdit = _.find(this.entries, (entry) => {
                    return entry.id == entryId
                });
                if (entryToEdit) {
                    EventBus.$emit("editEntry", entryToEdit);
                }
            }

        } else {
            const timeClicked = this.timePixelUtilities.timeAtCoordinate(evt);
            if (timeClicked == null) {
                return;
            }
            this.startedAt = timeClicked;
            this.endedAt = new TimeOfDay(this.startedAt.hour, this.startedAt.minute).plus(15);
            this.$emit("selectionStarted");
            this.isSelecting = true;
        }
    }

    mouseUp() {
        if (this.notLeftClick) {
            return;
        }
        if (this.isSelecting) {
            this.$emit("selectionEnded");
            this.isSelecting = false;
            EventBus.$emit("newEntry", this.date, this.startedAt, this.endedAt);
        }
    }

    private static allTimeOfDays(): Array<TimeOfDay> {
        let times = new Array<TimeOfDay>();
        for (let hour = 0; hour <= 23; hour++) {
            for (let minute = 0; minute < 60; minute += 1) {
                times.push(new TimeOfDay(hour, minute));
            }
        }
        return times;
    }

    private entriesForTime(entries: Array<Entry>, time: TimeOfDay): Array<Entry> {
        return _.filter(entries, (entry: Entry) => {
            return entry.isDuring(time);
        })
    }

    private overLappingEntries(entries: Array<Entry>): Array<Entry> {
        let overlaps = new Array<Entry>();
        // IDE doesn't want to believe it exists but it does...
        // @ts-ignore
        _.combinations(entries, 2).forEach((pairs) => {
            let first = pairs[0];
            let second = pairs[1];

            if ((this.entryColumns.get(first)) == (this.entryColumns.get(second))) {
                overlaps.push(first);
                overlaps.push(second);
            }
        });
        return _.uniqBy(overlaps, (entry) => {
            return entry.id;
        });
    }

    private handleOverlaps(allEntries: Array<Entry>): Array<Entry> {
        // so stupid it might work! let's go down hour by hour and see if we
        // get two or more entries... if so, give them what we call
        // "columnOffset" and lower their width.
        Day.allTimeOfDays().forEach(time => {
            let possiblyOverlappingEntries = this.overLappingEntries(this.entriesForTime(allEntries, time));
            if (possiblyOverlappingEntries.length > 1) {
                // let's increment the column of the last entry here..
                let lastEntry = possiblyOverlappingEntries[possiblyOverlappingEntries.length - 1];
                let column = this.entryColumns.get(lastEntry);
                if (column !== undefined && column !== null) {
                    this.entryColumns.set(lastEntry, column + 1);
                    if (column > 10) {
                        throw "This is not working! Too many columns!";
                    }
                }
                // now start over again...
                this.handleOverlaps(allEntries);
            }
        });
        // if we make it all the way to the end of this crap and don't find any...
        // we are all done!
        return allEntries;
    }

}
