// @ts-ignore
import SwaggerUI from 'swagger-ui';

export default class Swagger {
    bind() {
        SwaggerUI({
            dom_id: '#swagger',
            url: "/api/swagger_doc.json"
        })
    }
}

