import 'whatwg-fetch';
import Entry, {EntryData} from "./entry";
import _ from "lodash";
// @ts-ignore
import moment from 'moment';
import {cookie} from "./cookie";
import Service, {ServiceData} from "./service";
import queryString from 'query-string';

enum HTTPMethod { GET = "GET", PUT = "PUT", POST = "POST", PATCH = "PATCH"}

export function api(): API {
    let token = cookie.read('token');
    let employeeId = Number(cookie.read('employee_id'));
    if (token && employeeId) {
        return new API(token, employeeId);
    } else {
        throw "Could not get token or maybe employeeId!";
    }
}

function apiBaseURL(): URL {
    return new URL(window.location.protocol + "//" + window.location.host + "/");
}

export default class API {

    private readonly token: string;
    private readonly employeeId: number;

    constructor(token: string, employeeId: number) {
        this.token = token;
        this.employeeId = employeeId;
    }

    static async checkPassword(username: string, password: string): Promise<Response> {
        const passwordURL = "/api/auth/password_strength.json"
        const apiURL = new URL(passwordURL, apiBaseURL());
        apiURL.searchParams.set('username', username);
        apiURL.searchParams.set('password', password);
        return fetch(apiURL.toString(), {
            method: 'GET',
            headers: {'Content-Type':'application/x-www-form-urlencoded'}
        }).then( response => {
            return response.json()
        }).then( responseJSON => {
            return responseJSON
        }).catch( (error: Error) => {
            throw error
        })
    }

    async saveEntry(entry: Entry) {
        // see if this is new or existing...
        const httpMethod = entry.id == null ? HTTPMethod.POST : HTTPMethod.PATCH;

        const entriesURL = "/api/v1/entries.json";

        const requestOptions: RequestInit = {
            method: httpMethod.toString()
        };

        let entryData = entry.toData();
        entryData.employee_id = this.employeeId;

        const url = this.buildAPIURL(entriesURL, entryData);

        return fetch(url.toString(), requestOptions).then( response => {
            if (!response.ok) {
                throw new Error(response.statusText)
            }
            return response.json();
        }).then((data: EntryData) => {
            return this.buildEntry(data);
        }).catch((error: Error) => {
            throw error
        });
    }

    async customerAutoComplete(input: String): Promise<Array<String>> {
        const customerAutoCompleteURL = "/api/v1/autocomplete_customers.json";

        const params: any = {
            input: input
        };

        const url = this.buildAPIURL(customerAutoCompleteURL, params);

        return fetch(url.toString())
            .then(response => {
                if (!response.ok) {
                    throw new Error(response.statusText)
                }
                return response.json()
            })
            .then((data:Array<String>) => {
                return data;
            })
            .catch((error: Error) => {
                throw error
            });
    }

    async services(): Promise<Service[]> {
        const servicesURL =  "/api/v1/services.json";

        const url = this.buildAPIURL(servicesURL, {});
        return fetch(url.toString()).then(response => {
            if (!response.ok) {
                throw new Error(response.statusText)
            }
            return response.json()
        }).then((data:Array<ServiceData>) => {
            return _.map(data, (serviceHash) => {
                return this.buildService(serviceHash);
            });
        }).catch((error: Error) => {
                throw error
        });
    }

    async entries(startTime: Date, endTime: Date): Promise<Entry[]> {

        const entriesURL: string = "/api/v1/entries.json";

        const params: any = {
            start_time: startTime,
            end_time: endTime,
            user_id: this.employeeId
        };

        const url = this.buildAPIURL(entriesURL, params);

        return fetch(url.toString())
            .then(response => {
                if (!response.ok) {
                    throw new Error(response.statusText)
                }
                return response.json()
            })
            .then((data:Array<EntryData>) => {
                return _.map(data, (entryHash) => {
                    return this.buildEntry(entryHash);
                });
            })
            .catch((error: Error) => {
                throw error
            });
    }

    private buildAPIURL(url: string, params: any) {
        const apiURL = new URL(url, apiBaseURL());
        Object.keys(params).forEach(key => {
            return apiURL.searchParams.append(key, params[key]);
        });
        apiURL.searchParams.append("token", this.token);
        return apiURL;
    }

    private buildEntry(entryHash): Entry {
        let startTime: Date = moment(entryHash.start_time).toDate();
        let endTime: Date = moment(entryHash.end_time).toDate();
        let customerName: string = entryHash.customer_name;
        let serviceName: string = entryHash.service_name;
        let description = entryHash.description;
        let entryId = entryHash.entry_id;

        return new Entry(entryId, customerName, serviceName, startTime, endTime, description);
    }

    async deleteEntry(entry: Entry) {

        const entriesURL = "/api/v1/entries.json";

        const requestOptions: RequestInit = {
            method: "DELETE"
        };

        const url = this.buildAPIURL(entriesURL, {
            entry_id: entry.id
        });

        return fetch(url.toString(), requestOptions).then( response => {
            if (!response.ok) {
                throw new Error(response.statusText)
            }
            return response.json();
        }).then((_) => {
            return true
        }).catch((error: Error) => {
            throw error
        });
    }

    private buildService(serviceHash: ServiceData): Service {
        return new Service(serviceHash.service_id, serviceHash.name);
    }
}