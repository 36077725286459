











































































































































import Vue from "vue";
import WorkWeek from "../components/workweek.vue";
import Modal from "../components/modal.vue";
import EntryCell from "../components/entry-cell.vue";
import Autocomplete from "../components/autocomplete.vue";
// @ts-ignore
import moment from "moment";
// @ts-ignore
import _ from "lodash";
import API, { api } from "../javascripts/api";
import Component from "vue-class-component";
import TimeOfDay from "../javascripts/time-of-day";
import { EventBus } from "../javascripts/app";
import Entry from "../javascripts/entry";
// @ts-ignore
import Datepicker from "vuejs-datepicker";
import { cookie } from "../javascripts/cookie";
import Service from "../javascripts/service";
import Vuex from 'vuex';
import { WorklogState } from '../javascripts/store';
import { constructDate } from "../javascripts/work_week_generator";

@Component({
  name: "worklog-component",
  components: {
    WorkWeek,
    Modal,
    Datepicker,
    EntryCell,
    Autocomplete
  }
})
export default class WorklogComponent extends Vue {
  date: Date = new Date();
  entryBeingEdited: Entry | null = null;
  entryDate = new Date();
  showModal = false;
  startTime: Date = new Date();
  endTime: Date = new Date();
  entries: Array<Entry> = [];
  services: Array<Service> = [];
  escHandler = (evt: Event) => {};
  dataLoaded: Boolean = false;

  get dayOfWeek(): string {
    return (this.entryDate as Date).toLocaleString("en-us", {
      weekday: "long"
    });
  }

  // used to exclude dates outside of the current week...
  get disabledDates() {
    let self = this;
    const disabledOutsideOf = self.date;
    return {
      customPredictor: function(date: Date) {
        let startOfPeriod = moment(disabledOutsideOf)
          .startOf("week")
          .toDate();
        let endOfPeriod = moment(disabledOutsideOf)
          .endOf("week")
          .toDate();
        if (date < moment(startOfPeriod).toDate()) {
          return true;
        } else if (date > moment(endOfPeriod).toDate()) {
          return true;
        }
        return false;
      }
    };
  }

  // functions...
  seekWeek(numberOfWeeks: number) {
    this.dataLoaded = false;
    this.date = moment(this.date)
      .add(numberOfWeeks * 7, "days")
      .toDate();
    // noinspection JSIgnoredPromiseFromCall
    this.refreshEntries();
  }

  async refreshEntries() {
    let startOfPeriod = moment(this.date)
      .startOf("week")
      .toDate();
    let endOfPeriod = moment(this.date)
      .endOf("week")
      .toDate();
    this.entries = await api().entries(startOfPeriod, endOfPeriod);
    this.dataLoaded = true;
  }

  tearDownModal(after?: (() => void) | null) {
    this.showModal = false;
    document.removeEventListener("keydown", this.escHandler);
    if (after) {
      after();
    }
  }

  async ok() {
    // let's replace it in the array...
    let updatedEntry = this.entryBeingEdited as Entry;
    if (updatedEntry !== null) {

      // validate entry first!
      if (_.isEmpty(updatedEntry.customerName)) {
        alert("You may not log hours without a customer!");
        return;
      }

      this.tearDownModal(async () => {
        await api().saveEntry(updatedEntry);
        await this.refreshEntries();
      });
    }
  }

  cancel() {
    this.tearDownModal();
  }

  async deleteEntry() {
    let entryToDelete = this.entryBeingEdited as Entry;
    if (entryToDelete !== null) {
      this.tearDownModal(async () => {
        await api().deleteEntry(entryToDelete);
        await this.refreshEntries();
      });
    }
  }

  customFormat(date: Date): string {
    return moment.utc(date, "DD-MM-YYYY").format("MM/DD/YYYY");
  }

  focusHours() {
    // TODO: AHHHH this should NOT be in a setTimeout!
    // the right answer is to wait until the modal animation is done!
    setTimeout(() => {
      // we want to select startHours by default...
      const entry: Entry = this.entryBeingEdited as Entry;

      if (entry !== null) {
        let elementToFocus: HTMLElement;
        if (entry.id) {
          elementToFocus = $('textarea[name="description"]').first()[0];
        } else {
          elementToFocus = $('input[name="start_time"]').first()[0];
        }
        if (elementToFocus == null) {
          throw "Can't force focus!";
        }
        elementToFocus.focus();
      }
    }, 10);
  }

  handleNewEntry(
    date: Date,
    startTimeOfDay: TimeOfDay,
    endTimeOfDay: TimeOfDay
  ) {
    let startTime = constructDate(date, startTimeOfDay);
    let endTime = constructDate(date, endTimeOfDay);

    // let's construct an entry right here...
    this.entryBeingEdited = new Entry(null, null, null, startTime, endTime, "");
    this.showModal = true;
    this.focusHours();
    // now let's listen for esc...
    document.addEventListener("keydown", this.escHandler);
  }

  get hideEarlyMorning() {
    return this.$store.state.hideEarlyMorning
  }

  set hideEarlyMorning(val) {
    this.$store.dispatch('toggleEarlyMorning');
  }

  async mounted() {
    EventBus.$on(
      "newEntry",
      (date: Date, startTimeOfDay: TimeOfDay, endTimeOfDay: TimeOfDay) => {
        this.handleNewEntry(date, startTimeOfDay, endTimeOfDay);
      }
    );

    EventBus.$on("editEntry", (entry: Entry) => {
      this.entryBeingEdited = _.cloneDeep(entry);
      this.showModal = true;
      this.focusHours();
      // now let's listen for esc...
      document.addEventListener("keydown", this.escHandler);
    });

    // set up ALT+N shortcut
    const self = this;
    document.addEventListener("keydown", zEvent => {
      if (zEvent.altKey && zEvent.key === "n") {
        const date = self.date;
        const startTime = new TimeOfDay(9, 0);
        const endTime = new TimeOfDay(10, 0);
        // TODO: should probably default to today, but focus
        // on date...
        self.handleNewEntry(date, startTime, endTime);
      }
    });

    // handle modal close on ESC
    this.escHandler = (zEvent: Event) => {
      if ((<KeyboardEvent>zEvent).key == "Escape") {
        self.tearDownModal();
      }
    };

    this.services = await api().services();

    await this.refreshEntries();
  }
}
