





























import Component from 'vue-class-component';
import Day from './day.vue';
import EntryCell from './entry-cell.vue';
import {Prop} from 'vue-property-decorator';
import Vue from 'vue';
import Entry from "../javascripts/entry";
import * as _ from "lodash";
import {EventBus} from "../javascripts/app";
import TimeOfDay from "../javascripts/time-of-day";
import {getWorkWeek} from "../javascripts/work_week_generator";

@Component({
        name: 'work-week',
        components: {
            Day, EntryCell
        }
    })
    export default class WorkWeek extends Vue {
        @Prop(Date) date!: Date;
        @Prop(Array) entries!: Array<Entry>;
        @Prop(Boolean) dataLoaded!: boolean = false;

        get workWeek(): Date[] {
          return getWorkWeek(this.date);
        }

        newClicked() {
            // default shit for now...
            const startedAt = new TimeOfDay(9, 0);
            const endedAt = new TimeOfDay(10, 0);
            EventBus.$emit("newEntry", this.date, startedAt, endedAt);
        }

        get formattedDate(): String {
            return this.date.toLocaleDateString()
        }

        get totalHours(): number {
           if (this.entries.length === 0) {
             return 0;
           }
           return _.sumBy(this.entries, function(e) {
             return e.durationInHours;
           });
        }

        entriesFor(date: Date): Array<Entry> {
            return _.filter(this.entries, (entry: Entry) => {
                return entry.isOnDate(date);
            });
        }
    }
