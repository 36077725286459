import _ from "lodash";

export default class ElementBoundsFinder {

    private static isMouseEventInClientArea(event: MouseEvent, element: Element): boolean
    {
        const rect = element.getBoundingClientRect();
        const minX = rect.left + element.clientLeft;
        const x = event.clientX;
        if (x < minX || x >= minX + element.clientWidth) return false;
        const minY = rect.top + element.clientTop;
        const y = event.clientY;
        return !(y < minY || y >= minY + element.clientHeight);
    }

    static firstInBoundsWithClass(event: MouseEvent, clazz: string): HTMLElement | null {
        // get all "hour" elements...
        let hourElements: Element[] = Array.from(document.getElementsByClassName(clazz));

        // after that we can get their bounds and see if the cursor is inside of it...
        const hourElement: Element | undefined = _.find(hourElements, (element: Element) => {
            return this.isMouseEventInClientArea(event, element);
        });

        return hourElement as HTMLElement || null;
    }
}