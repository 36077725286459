<template>
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-container">

                    <slot name="header" class="modal-header">
                       default header
                    </slot>

                    <slot name="body" class="modal-body">
                       default body
                    </slot>

                    <slot name="footer" class="modal-footer">
                        default footer
                    </slot>
                </div>
            </div>
        </div>
    </transition>
</template>

<script type="typescript">
    export default {
        name: "modal"
    }
</script>

<style lang="less" scoped>
    @color_1: #42b983;
    @font_family_1: Helvetica, Arial, sans-serif;
    @background_color_1: rgba(0, 0, 0, .5);
    @background_color_2: #fff;

    /*
         * The following styles are auto-applied to elements with
         * transition="modal" when their visibility is toggled
         * by Vue.js.
         *
         * You can easily play with the modal transition by editing
         * these styles.
         */
    .modal-mask {
        position: fixed;
        z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: @background_color_1;
        display: table;
        transition: opacity .3s ease;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }

    .modal-container {
        width: 50vw;
        margin: 0 auto;
        padding: 20px 30px;
        background-color: @background_color_2;
        border-radius: 2px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
        transition: all .3s ease;
        font-family: @font_family_1;
    }

    .modal-header {
        padding: 0;
        h3 {
            margin-top: 0;
            color: @color_1;
        }
    }

    .form-group {
        margin-bottom: 0.5em;
    }

    .modal-body {
        margin: 0 0;
    }

    .modal-default-button {
        float: right;
    }

    .modal-enter {
        opacity: 0;
        .modal-container {
            -webkit-transform: scale(1.1);
            transform: scale(1.1);
        }
    }

    .modal-leave-active {
        opacity: 0;
        .modal-container {
            -webkit-transform: scale(1.1);
            transform: scale(1.1);
        }
    }
</style>