import $ from 'jquery';
import _ from 'lodash';
import API from './api';
import { cookie } from "./cookie";

let okToSubmit = false;

export default class PasswordChecker {
    bind() {

        // block enter key from submitting form
        $(window).keydown(function (event) {
            if ((event.keyCode == 13) && (okToSubmit == false)) {
                event.preventDefault();
                return false;
            }
        });

        // let's bind to the controls we are interested in.
        // the idea is lets look for keyup on password
        // and password confirm. if they match and are non-blank
        // we will post them to a API and get back the strength
        // and display to user...
        let passwordElement = $('#password');
        let passwordConfirmElement = $('#confirm_password');

        $('#submit_button').hide();

        _.each([passwordElement, passwordConfirmElement], (element) => {
            $(element).on('keyup', _.debounce(async () => {
                let password = $('#password').val();
                let confirmPassword = $('#confirm_password').val();
                if (password != "") {
                    // post to API here...
                    let username = cookie.read('username');
                    if (username == null || password == undefined) {
                        throw ("Username/password cannot be determined.")
                    } else {
                        let result = await API.checkPassword(username, password.toString());
                        let strength = result['strength'];
                        $('#password_strength_result').text("Password strength is: " + strength);
                        if (password == confirmPassword && (strength == 'good' || strength == 'strong')) {
                            $('#submit_button').show();
                            okToSubmit = true;
                        } else {
                            $('#submit_button').hide();
                            // append the fact that we won't permit the submit
                            $('#password_strength_result').append(" (Password must match and be strong to submit.)");
                            okToSubmit = false;
                        }
                    }
                }
            }, 500));
        })
    }
}