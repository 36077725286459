import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
import WorklogComponent from "../components/worklog-component.vue";
import $ from "jquery";
import { store } from './store';

$(function() {
  // @ts-ignore
  $('[data-toggle="tooltip"]').tooltip({
    html: true
  });
});

export default class Home {
  public bind() {
    // let's bind that calendar right here...
    new Vue({
      el: "#component-area",
      store,
      data: {},
      components: {
        WorklogComponent
      }
    });
  }
}
