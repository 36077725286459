import TimeOfDay from "../javascripts/time-of-day";

export function getWorkWeek(date: Date) {
  let today = date;
  let lastSunday = new Date(today.setUTCDate(today.getUTCDate() - today.getDay()));
  let weekDays: Array<Date> = [];
  for (let x = 0; x < 7; x++) {
    let tomorrow = new Date(lastSunday.getTime());
    // TODO: bug suspected here!
    tomorrow.setUTCDate(tomorrow.getUTCDate() + x);
    tomorrow.setHours(0);
    tomorrow.setMinutes(0);
    tomorrow.setMilliseconds(0);
    tomorrow.setSeconds(0);
    weekDays.push(tomorrow);
  }
  return weekDays
}

export function constructDate(date: Date, timeOfDay: TimeOfDay): Date {
  let newDate = new Date(date.getTime());
  newDate.setUTCDate(date.getUTCDate());
  newDate.setMonth(date.getMonth());
  newDate.setFullYear(date.getFullYear());
  newDate.setHours(timeOfDay.hour, timeOfDay.minute, 0);
  return newDate;
}